import http from '../clients/Axios'

export default class JpinService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/jpin/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(params = '') {
    const res = await http.get(`/jpin${params}`)
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/jpin/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/jpin', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/jpin/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/jpin/${id}`)
    return res
  }
}
